<template>
  <vs-card>
    <div slot="header">
      <h3>
        {{ title }}
      </h3>
    </div>
    <quillEditor :pages="pages"></quillEditor><br />

    <vs-divider></vs-divider>

      <vs-row vs-justify="flex-end" vs-w="12">
        <vs-button class="mr-6" color="danger" type="filled" @click="$router.replace('/admin/cms-pages')">Cancel</vs-button>
        <vs-button color="primary" @click="savePageDetails">Save</vs-button>
      </vs-row>

  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import quillEditor from "../Editor";
import moment from "moment";

export default {
  name: "pageViewEditDetail",
  components: {
    quillEditor,
  },
  data: () => ({
    pages: {
      content: "",
    },
    title: "",
  }),
  methods: {
    ...mapActions("page", ["fetchPageDetails", "updatePageDetails"]),
    getPageDetail(id) {
      let self = this;
      this.fetchPageDetails(id).then((res) => {
        this.pages.content = res.data.data.content;
        this.title = res.data.data.title;
      });
    },
    savePageDetails() {
      let id = this.$route.params.id;
      let info = this.pages;
      this.updatePageDetails({ id, info }).then((res) => {
        this.$vs.notify({
          title: "Update Page Content",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
      });
    },
  },
  created() {
    let pageId = this.$route.params.id;
    this.getPageDetail(pageId);
  },
};
</script>
